const Mail = () => {
  return (
    <svg
      className="w-full h-full"
      viewBox="0 0 56 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.5254 0.259033C53.4111 0.259033 55.7522 2.5991 55.7522 5.4858C55.7522 7.13006 54.9791 8.67631 53.6615 9.66722L29.9668 27.4382C28.7255 28.3638 27.0268 28.3638 25.7854 27.4382L2.09071 9.66722C0.774542 8.67631 0 7.13006 0 5.4858C0 2.5991 2.34007 0.259033 5.22677 0.259033H50.5254ZM23.6947 30.2258C26.1774 32.0879 29.5748 32.0879 32.0575 30.2258L55.7522 12.4548V35.1042C55.7522 38.948 52.627 42.0732 48.7832 42.0732H6.96903C3.11973 42.0732 0 38.948 0 35.1042V12.4548L23.6947 30.2258Z"
        fill="white"
      />
    </svg>
  );
};

export default Mail;
