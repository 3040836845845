const Location = () => (
  <svg
    className="w-full h-full"
    viewBox="0 0 47 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5 0C17.2697 0.00737244 11.2966 2.49315 6.89113 6.91205C2.48562 11.331 0.00737509 17.3222 2.50016e-05 23.5714C-0.00743708 28.6783 1.65566 33.6467 4.7342 37.7143C4.7342 37.7143 5.37511 38.5607 5.47979 38.6829L23.5 60L41.5288 38.6721C41.6227 38.5586 42.2658 37.7143 42.2658 37.7143L42.2679 37.7079C45.3449 33.642 47.0073 28.676 47 23.5714C46.9926 17.3222 44.5144 11.331 40.1089 6.91205C35.7034 2.49315 29.7303 0.00737244 23.5 0ZM23.5 32.1429C21.8099 32.1429 20.1577 31.6402 18.7524 30.6983C17.3471 29.7565 16.2518 28.4178 15.605 26.8516C14.9583 25.2853 14.789 23.5619 15.1188 21.8992C15.4485 20.2365 16.2624 18.7092 17.4575 17.5105C18.6526 16.3118 20.1752 15.4954 21.8329 15.1647C23.4905 14.834 25.2087 15.0037 26.7702 15.6525C28.3317 16.3012 29.6663 17.3998 30.6053 18.8094C31.5443 20.219 32.0454 21.8762 32.0454 23.5714C32.0426 25.8438 31.1414 28.0224 29.5394 29.6292C27.9374 31.2361 25.7655 32.14 23.5 32.1429Z"
      fill="#29CCC4"
    />
  </svg>
);

export default Location;
