const Linkedin = () => {
  return (
    <svg
      className="w-full h-full"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.82287e-07 3.25828C4.82287e-07 2.39413 0.343282 1.56537 0.954326 0.95433C1.56537 0.343286 2.39413 4.34374e-06 3.25827 4.34374e-06H35.7382C36.1664 -0.000695089 36.5906 0.0830795 36.9864 0.246532C37.3823 0.409984 37.742 0.649903 38.0449 0.952555C38.3479 1.25521 38.5882 1.61465 38.7521 2.01029C38.916 2.40594 39.0002 2.83003 39 3.25828V35.7382C39.0005 36.1665 38.9165 36.5907 38.7528 36.9866C38.5892 37.3824 38.3491 37.7421 38.0463 38.0451C37.7435 38.348 37.3839 38.5883 36.9882 38.7522C36.5925 38.916 36.1683 39.0002 35.74 39H3.25827C2.83024 39 2.4064 38.9157 2.01098 38.7518C1.61555 38.588 1.25628 38.3478 0.953699 38.0451C0.651118 37.7423 0.411152 37.3829 0.247513 36.9874C0.0838735 36.5919 -0.000232397 36.168 4.82287e-07 35.74V3.25828ZM15.4369 14.8696H20.7179V17.5216C21.4801 15.9971 23.4301 14.625 26.3605 14.625C31.9782 14.625 33.3095 17.6617 33.3095 23.2334V33.5542H27.6244V24.5026C27.6244 21.3295 26.8621 19.539 24.9263 19.539C22.2406 19.539 21.1238 21.4695 21.1238 24.5026V33.5542H15.4369V14.8696ZM5.68691 33.3113H11.3738V14.625H5.68691V33.3096V33.3113ZM12.1875 8.53037C12.1982 9.01729 12.1116 9.50144 11.9327 9.95443C11.7537 10.4074 11.4861 10.8201 11.1455 11.1682C10.805 11.5164 10.3983 11.793 9.94935 11.9819C9.50042 12.1707 9.01829 12.268 8.53125 12.268C8.04421 12.268 7.56208 12.1707 7.11315 11.9819C6.66422 11.793 6.25753 11.5164 5.91695 11.1682C5.57638 10.8201 5.30877 10.4074 5.12985 9.95443C4.95092 9.50144 4.86428 9.01729 4.875 8.53037C4.89605 7.57461 5.2905 6.66509 5.97389 5.99658C6.65727 5.32808 7.57526 4.95374 8.53125 4.95374C9.48724 4.95374 10.4052 5.32808 11.0886 5.99658C11.772 6.66509 12.1665 7.57461 12.1875 8.53037V8.53037Z"
        fill="#F8F8F8"
      />
    </svg>
  );
};

export default Linkedin;
