const Github = () => {
  return (
    <svg
      className="w-full h-full"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 0C8.72625 0 0 8.94697 0 19.9932C0 28.8402 5.58187 36.3127 13.3331 38.9618C14.3081 39.1367 14.6737 38.5369 14.6737 38.0121C14.6737 37.5373 14.6494 35.9628 14.6494 34.2884C9.75 35.2131 8.4825 33.0638 8.0925 31.9392C7.87313 31.3644 6.9225 29.59 6.09375 29.1151C5.41125 28.7403 4.43625 27.8156 6.06938 27.7906C7.605 27.7656 8.70187 29.2401 9.0675 29.8399C10.8225 32.8639 13.6256 32.0142 14.7469 31.4893C14.9175 30.1898 15.4294 29.3151 15.99 28.8152C11.6512 28.3154 7.1175 26.591 7.1175 18.9436C7.1175 16.7693 7.87313 14.9699 9.11625 13.5704C8.92125 13.0706 8.23875 11.0213 9.31125 8.2722C9.31125 8.2722 10.9444 7.74738 14.6737 10.3215C16.2337 9.87166 17.8912 9.64673 19.5487 9.64673C21.2062 9.64673 22.8638 9.87166 24.4237 10.3215C28.1531 7.72238 29.7862 8.2722 29.7862 8.2722C30.8587 11.0213 30.1763 13.0706 29.9813 13.5704C31.2244 14.9699 31.98 16.7443 31.98 18.9436C31.98 26.616 27.4219 28.3154 23.0831 28.8152C23.79 29.44 24.3994 30.6396 24.3994 32.514C24.3994 35.1881 24.375 37.3374 24.375 38.0121C24.375 38.5369 24.7406 39.1617 25.7156 38.9618C29.5867 37.6218 32.9504 35.0709 35.3335 31.6682C37.7165 28.2655 38.9988 24.1823 39 19.9932C39 8.94697 30.2738 0 19.5 0Z"
        fill="white"
      />
    </svg>
  );
};

export default Github;
